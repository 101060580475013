<template>
  <data-view-layout>
    <div
      slot="left-pane"
      class="left-pane"
    >
      <!-- 今日整点车辆上线率统计 -->
      <data-view-card
        v-loading="loading1"
        style="width:100%;height:33.33%;"
      >
        <chart-line
          style="width:100%;height:100%;"
          :title="'今日整点车辆上线率统计'"
          :label="'上线率'"
          :unit="'%'"
          :data="leftPane.onlineRate"
        />
      </data-view-card>

      <!-- 今日车辆上线率 -->
      <data-view-card
        v-loading="loading1"
        style="width:100%;height:33.33%;margin-top:10px;"
      >
        <chart-bar
          style="width:100%;height:100%;"
          :title="'今日车辆上线率'"
          :unit="'%'"
          :data="leftPane.compareRates"
        />
      </data-view-card>

      <!-- 今日单车超速次数 -->
      <data-view-card
        v-loading="loading2"
        class="mt-11"
        style="width:100%; height: 33.33%;"
      >
        <chart-bar3
          style="width:100%;height:100%;"
          :data="leftPane.overSpeedTimes"
          :title="'今日单车超速次数'"
          :unit="'次'"
        />
      </data-view-card>
    </div>

    <div
      slot="middle-pane"
      class="middle-pane"
    >
      <!-- 中上地图 type：0总览，1商砼，2砂浆，3水泥，0车辆 -->
      <data-view-map
        :type="0"
        style="width:100%;height:52.81%;"
        :totals="middlePane.totals"
        @area-change="areaChange"
      />
      <!-- 正中间表格: 区年月日产量 -->
      <data-view-card
        v-loading="loading4"
        class="mt-11"
        style="width:100%;height:45.85%;position:relative;"
      >
        <div class="table-cate-bar">
          <div
            v-for="({name, id}) in middlePane.rankCates"
            :key="id"
            class="cate-item"
            :class="{active: middlePane.activeCate==id}"
            @click="selectedCate(id)"
          >
            {{ name }}
          </div>
        </div>
        <resize-wrap class="h-100-p">
          <template v-slot="{height}">
            <scroll-table
              v-if="height"
              :height="height"
              :data="rankTableData"
              :titles="rankTableTitles"
            />
          </template>
        </resize-wrap>
      </data-view-card>
    </div>

    <div
      slot="right-pane"
      class="right-pane"
    >
      <data-view-card
        v-loading="loading2"
        style="width:100%; height: 38.16%;position:relative;"
      >
        <div class="title-line">
          <span class="prefix-title" />
          今日驾驶行为占比分析
        </div>
        <div
          class="flex justify-center align-center"
          style="width:100%;height:calc(90% - 12px);position:absolute; top: calc(10% + 12px)"
        >
          <template v-if="rightPane.actionRate.length > 0">
            <chart-pie
              v-for="(item,index) in rightPane.actionRate"
              :key="index"
              style="width:33.33%;height:50%;"
              :data="item"
              :color="rightPane.actionColors[index]"
            />
          </template>
          <div
            v-else
            class="fs-12"
          >
            暂无数据
          </div>
        </div>
      </data-view-card>

      <!-- 本月单车报警排名 -->
      <data-view-card
        v-loading="loading3"
        style="width:100%; height: 30.91%; margin-top: 10px;"
      >
        <chart-bar4
          style="width:100%;height:100%;"
          :data="rightPane.warnRank"
          title="本月单车报警排名"
          unit="次"
        />
      </data-view-card>

      <!-- 近30天警报总数 -->
      <data-view-card
        v-loading="loading3"
        class="mt-11"
        style="width:100%;height:30.91%;"
      >
        <chart-line
          style="width:100%;height:100%;"
          label="报警数"
          :data="rightPane.warnCount"
          title="近30天报警总数"
          unit="次"
        />
      </data-view-card>
    </div>
  </data-view-layout>
</template>

<script>
import { ChartLine, ChartBar, ChartBar3, ChartBar4, ChartPie } from '@/components/Charts'
import { DataViewLayout, DataViewCard } from '@/components/DataView'
import { getVehiData, getVehiOnlineRate, getVehiTodayActData, getVehiWarnData, getVehiRankData } from '@/apis/dataView'
import ScrollTable from '@/components/ScrollTable'
import DataViewMap from './components/DataViewMap'
import XEUtils from 'xe-utils'

export default {
  components: {
    DataViewLayout,
    DataViewCard,
    ChartLine,
    ChartBar,
    ChartBar3,
    ChartBar4,
    ChartPie,
    ScrollTable,
    DataViewMap
  },
  data () {
    return {
      leftPane: {
        onlineRate: { // 今日上线率
          xAxis: [],
          yAxis: []
        },
        compareRates: { // 各企业上线率
          xAxis: [],
          yAxis: []
        },
        overSpeedTimes: { // 超速次数
          xAxis: [],
          yAxis: []
        }
      },
      middlePane: {
        // 地图头部数据
        totals: [
          { name: '企业总数', value: 0, prop: 'compCount' },
          { name: '生产线总数', value: 0, prop: 'queuCount' },
          { name: '车辆总数', value: 0, prop: 'vehiCount' },
          { name: '车辆上线总数', value: 0, prop: 'vehiOnlineCount' },
          { name: '报警总数', value: 0, prop: 'warnCount' }
        ],
        // 区域产量排名表格
        rank: {
          vehiMileageRankDtoList: [],
          vehiNumRankDtoList: []
        },
        rankCates: [
          { id: 'vehiNum', name: '车辆数排名' },
          { id: 'vehiMileage', name: '单车里程排名' }
        ],
        rankPropMap: {
          vehiNum: 'vehiNumRankDtoList',
          vehiMileage: 'vehiMileageRankDtoList'
        },
        activeCate: 'vehiNum'
      },
      rightPane: {
        actionRate: [
          { title: '', value: 0 },
          { title: '', value: 0 },
          { title: '', value: 0 },
          { title: '', value: 0 },
          { title: '', value: 0 },
          { title: '', value: 0 }
        ], // 今日驾驶行为占比分析
        actionColors: [undefined, '#E99D60', '#CE95F1', '#4880FF', '#8EEAF8', '#FEC618'],
        warnRank: {
          xAxis: [],
          yAxis: []
        },
        warnCount: {
          xAxis: [],
          yAxis: []
        },
        colors: ['#DA0051', '#FFB508', '#01BD8D']
      },
      loading1: false,
      loading2: false,
      loading3: false,
      loading4: false
    }
  },
  computed: {
    // 排名表格标题
    rankTableTitles () {
      const { middlePane: { activeCate } } = this
      const titles = {
        vehiNum: [
          { name: '排名', prop: 'rank' },
          { name: '区域名称', prop: 'name' },
          { name: '车辆数(辆)', prop: 'vehiCount' },
          { name: '上线数(辆)', prop: 'onlineVehiCount' }
        ],
        vehiMileage: [
          { name: '排名', prop: 'rank' },
          { name: '区域名称', prop: 'name' },
          { name: '里程数(km)', prop: 'totalMileage' },
          { name: '车辆数(辆)', prop: 'vehiCount' },
          { name: '单车里程数(km)', prop: 'avgMileage' }
        ]
      }
      return titles[activeCate]
    },
    rankTableData () {
      const { middlePane: { activeCate, rankPropMap, rank } } = this
      const prop = rankPropMap[activeCate]
      return rank[prop]
    }
  },
  methods: {
    areaChange (params) {
      this.initVehiData(params)
      this.initVehiOnlineRate(params)
      this.initVehiTodayActData(params)
      this.initVehiWarnData(params)
      this.initVehiRankData(params)
    },

    // 获取地图上面企业车辆数据
    async initVehiData (params = {}) {
      const { middlePane } = this
      const res = await getVehiData(params)
      if (res.code == 200) {
        const data = res.data || {}
        middlePane.totals = middlePane.totals.map((item) => {
          const value = data[item.prop] || 0
          item.value = value
          return item
        })
      } else {
        this.$message.error(res.msg)
      }
    },

    // 获取左侧1,2折线图
    async initVehiOnlineRate (params = {}) {
      const { leftPane } = this
      this.loading1 = true
      const res = await getVehiOnlineRate(params)
      this.loading1 = false
      if (res.code == 200) {
        const data = res.data || {}
        const lineChartDto = data.lineChartDto || ({ lineList: [], valueList: [] })
        const baseDataCompareDto = data.baseDataCompareDto || ({ lineList: [], columnList: [] })
        leftPane.onlineRate.xAxis = lineChartDto.lineList
        leftPane.onlineRate.yAxis = lineChartDto.valueList
        leftPane.compareRates.xAxis = baseDataCompareDto.lineList
        leftPane.compareRates.yAxis = baseDataCompareDto.columnList
      } else {
        this.$message.error(res.msg)
      }
    },

    // 左下1 折线图， 右上1 饼图s
    async initVehiTodayActData (params = {}) {
      const { leftPane, rightPane } = this
      this.loading2 = true
      const res = await getVehiTodayActData(params)
      this.loading2 = false
      if (res.code == 200) {
        const data = res.data || {}
        const todayOverSpeedData = data.todayOverSpeedData || ({ lineList: [], valueList: [] })
        const rateDtoList = data.rateDtoList || []
        console.log(todayOverSpeedData, rateDtoList)
        leftPane.overSpeedTimes.xAxis = todayOverSpeedData.lineList
        leftPane.overSpeedTimes.yAxis = todayOverSpeedData.valueList
        if (rateDtoList.length > 0) {
          rightPane.actionRate = rateDtoList
        }
      } else {
        this.$message.error(res.msg)
      }
    },

    // 右下两个组件
    async initVehiWarnData (params = {}) {
      const { rightPane } = this
      this.loading3 = true
      const res = await getVehiWarnData(params)
      this.loading3 = false
      if (res.code == 200) {
        const data = res.data || {}
        const vehiCountRankDto = data.vehiCountRankDto || ({ lineList: [], valueList: [] })
        const warnCountDto = data.warnCountDto || ({ lineList: [], valueList: [] })
        rightPane.warnRank.xAxis = vehiCountRankDto.lineList
        rightPane.warnRank.yAxis = vehiCountRankDto.valueList
        rightPane.warnCount.xAxis = warnCountDto.lineList
        rightPane.warnCount.yAxis = warnCountDto.valueList
      } else {
        this.$message.error(res.msg)
      }
    },

    // 排名表格
    async initVehiRankData (params = {}) {
      const { middlePane } = this
      this.loading4 = true
      const res = await getVehiRankData(params)
      this.loading4 = false
      if (res.code == 200) {
        const data = res.data || {
          vehiMileageRankDtoList: [],
          vehiNumRankDtoList: []
        }
        middlePane.rank.vehiMileageRankDtoList = data.vehiMileageRankDtoList.map((item, i) => {
          item.rank = i + 1
          item.totalMileage = XEUtils.commafy(XEUtils.round(XEUtils.divide(item.totalMileage, 1000), 2))
          item.avgMileage = XEUtils.commafy(XEUtils.round(XEUtils.divide(item.avgMileage, 1000), 2))
          return item
        })
        middlePane.rank.vehiNumRankDtoList = data.vehiNumRankDtoList.map((item, i) => {
          item.rank = i + 1
          item.totalMileage = XEUtils.commafy(XEUtils.round(XEUtils.divide(item.totalMileage, 1000), 2))
          item.avgMileage = XEUtils.commafy(XEUtils.round(XEUtils.divide(item.avgMileage, 1000), 2))
          return item
        })
      } else {
        this.$message.error(res.msg)
      }
    },

    selectedCate (id) {
      this.middlePane.activeCate = id
    }
  }
}
</script>

<style lang="scss" scoped>
.middle-pane {
  flex: 1;
  margin: 0 11px;
  height: 100%;
  position: relative;
}

.left-pane,
.right-pane {
  box-sizing: border-box;
  color: #fff;
  width: 29.38%;
  height: 100%;
  padding-bottom: 21px;
}

.title-line {
  position: absolute;
  top: 8%;
  left: 3%;
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .prefix-title {
    display: block;
    width: 6px;
    height: 6px;
    background: #00FFFF;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.map-type-list {
  position: absolute;
  right: 0;
  bottom: 0;

  .map-type-item {
    margin-bottom: 10px;
    width: 74px;
    line-height: 26px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(90deg, #009CAE 0%, rgba(1, 27, 64, 0.6) 100%);

    &.active {
      background: linear-gradient(90deg, #D60E0C 0%, rgba(1, 27, 64, 0.6) 100%);
    }
  }
}

.table-cate-bar {
  position: absolute;
  top: -22px;
  display: flex;

  .cate-item {
    box-sizing: border-box;
    min-width: 60px;
    padding: 0 10px;
    line-height: 22px;
    background: #134F54;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 10px;

    &.active {
      background: #059F55;
    }
  }
}
::v-deep {
  .el-loading-mask {
    background: rgba(0, 0, 0, .45);
  }
  .el-loading-spinner .path{
    stroke: #26FFF8!important;
  }
}
</style>
